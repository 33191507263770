import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import Layout from '../components/layout';

const IndexPage = ({ data }) => (
  <Layout>
    <section className="hero">
      <section className="container column centre">
        <article className="avatar">
          <Img fluid={data.file.childImageSharp.fluid} />
        </article>
        <h4 className="text-centre">Rohan Benstead</h4>
        <span className="light">Full Stack Software Engineer</span>
        <span className="text-small lighter text-centre">
          React, Redux, GraphQL, Node.js, Express, Less/Sass, MySQL/Postgres,
          AWS, ES6
        </span>
        <br />
        <a
          href="mailto:me@rohanbenstead.com"
          className="text-centre thick higher"
        >
          me@rohanbenstead.com
        </a>
        <a
          href="https://www.linkedin.com/in/rohanbenstead/"
          target="_blank"
          rel="noopener noreferrer"
          className="text-centre thick higher"
        >
          LinkedIn
        </a>
      </section>
    </section>
  </Layout>
);

export default IndexPage;

export const query = graphql`
  query {
    file(relativePath: { eq: "avatar.jpeg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        fluid(maxWidth: 250) {
          ...GatsbyImageSharpFluid_noBase64
        }
      }
    }
  }
`;
